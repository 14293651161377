import React, { useState } from "react";
// import iphone from '../public/iphone_14_red_p.png'; // Tell webpack this JS file uses this image
import "./App.css";
import Modal from "./components/modal";
import Phone from "./components/phone";
import { Header } from "./components/header";
import Home from "./Page/Home";
import About from "./Page/About";
import Contact from "./Page/Contact";

function App() {
  const [toggle, setToggle] = useState<boolean>(false);

  const handleModal = () => {
    setToggle(!toggle);
  };

  const handleNavigate = (route: string) => {
    if (route === "modal") {
        handleModal();
    }
  };

  return (
    <>
      <Header navigate={handleNavigate} />
      <Home openModal={handleModal} />
      {/* <div className="h-screen flex flex-row flex-1  justify-center pb-24 relative overflow-x-hidden lg:overflow-x-visible">
        <Phone />
      </div> */}
      <About />
      <Contact />
      <Modal toggle={toggle} close={handleModal} />
    </>
  );
}

export default App;
