import React from "react";

function Home({ openModal }: { openModal: () => void }) {
  return (
    <section className="mt-10 lg:mt:0 pr-5 pl-5 lg:pr-0 lg:pl-24 flex justify-between flex-col lg:flex-row pb-8" style={{ gap: "34px" }}>
      <article>
        <h1 className="hidden">Elektreco</h1>
        <div className="h-9 lg:h-16 flex flex-row gap-6">
          <img
            style={{ color: "var(--secondary)" }}
            src="./logo_title.svg"
            alt="Elektroco Typo Logo Vert"
          />
        </div>
        <p
          className="text-base font-normal leading-6 tracking-tight max-w-lg py-16 text-justify"
          style={{ color: "var(--text-primary)" }}
        >
          Elektreco est une application mobile gratuite qui scanne le code-barres
          de vos appareils électriques (smartphones, ordinateurs, téléviseurs,
          lave-linges, tondeuses...) et vous donne leur indice de réparabilité.
          Nous vous guidons vers des achats plus éco-responsables en vous
          indiquant les appareils avec un meilleur indice, et nous vous
          redirigeons vers des services de réparation.
        </p>
        <button className="button_hero rounded-xl border py-3 px-8 text-base font-bold text-white" onClick={openModal}>
          Essayer l’application
        </button>
      </article>
      <article style={{}}>
        <img src="./home/repair.svg" alt="Breakdown Phone for repair" />
      </article>
    </section>
  );
}

export default Home;
