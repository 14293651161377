import React from "react";

function Home() {
  return (
    <section
      className="px-5 lg:px-24 flex flex-col lg:flex-row justify-between"
      style={{ gap: "34px" }}
    >
      <article>
        <div className="flex flex-row lg:justify-end">
          <h2
            id="about"
            className="text-5xl font-bold"
            style={{ color: "var(--title-primary)" }}
          >
            À propos
          </h2>
          <img src="" alt="" />
        </div>
        <p
          className="text-base font-normal leading-6 tracking-tight max-w-lg pt-8 text-justify"
          style={{ color: "var(--text-primary)" }}
        >
          Notre sensibilité pour <b>l’environnement</b> et notre désir de rendre
          la
          <b> réparation accessible à tous</b> sont à l’origine de ce projet
          ambitieux:
          <i style={{ color: "var(--title-primary)" }}>
            <b> Elektreco</b>
          </i>
          , une application mobile conçue pour transformer la façon dont nous
          interagissons avec nos appareils électriques.
          <br />
          <br /> L'histoire d'Elektreco commence avec la prise de conscience que
          la fabrication, la distribution et le remplacement fréquent de ces
          appareils génèrent une empreinte écologique considérable. Cette
          réalisation a façonné notre vision :{" "}
          <i>
            offrir aux <b>consommateurs</b> les moyens de prendre des{" "}
            <b>décisions éclairées</b> en faveur de la durabilité.
          </i>
          <br />
          <br />
          Notre objectif est simple mais ambitieux : donner à chacun le pouvoir
          de choisir des appareils électriques qui peuvent être <b>réparés</b> et
          entretenus facilement, prolongeant ainsi leur durée de vie et
          réduisant leur impact sur <b>notre planète</b>, et faciliter l’accès aux services de réparation.
        </p>
      </article>
      <article className="lg:order-first">
        <img src="./home/puzzle.svg" alt="Human staking puzzle pieces" />
      </article>
    </section>
  );
}

export default Home;
