import { useForm, SubmitHandler } from "react-hook-form";
import { createClient } from "@supabase/supabase-js";
import { useState } from "react";

const supabase = createClient(
  "https://rhxeiqqwuibtegiksrhz.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJoeGVpcXF3dWlidGVnaWtzcmh6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzkxNjA4MTcsImV4cCI6MTk5NDczNjgxN30.mr4RbG6qs_BUeNNFcMiECzYlBIg7J20zKOq2KvMNYh0"
);

interface FormModalProps {
  toggle: boolean;
  close: () => void;
}

interface IFormInput {
  mail: string;
  consent: boolean;
}

export default function FormModal({ toggle, close }: FormModalProps) {
  const [dberror, setDberror] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
      let { error } = await supabase
        .from("preregistration")
        .insert({ mail: data.mail });

      if (error) {
        asError();
      } else{
        close();
        reset();
      }


  };

  const asError = () => {
    setDberror(true);
    setTimeout(() => {
      setDberror(false);
    }, 10000);
  }

  let cssVisibility = "hidden";
  if (toggle) {
    cssVisibility = "flex";
  } else {
    cssVisibility = "hidden";
  }

  return (
    <div
      id="authentication-modal"
      aria-hidden="true"
      className={`fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-screen justify-center items-center ${cssVisibility} bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0`}
      // onClick={() => close()}
    >
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            onClick={() => close()}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="authentication-modal"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
              Enregistement à la liste d'attente
            </h3>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Votre email
                </label>
                <input
                  type="email"
                  // name="email"
                  id="email"
                  className={`bg-gray-50 border  text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
                    errors.mail
                      ? "border-red-300 focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  }`}
                  placeholder="jean.bernard@gmail.com"
                  // required
                  {...register("mail", {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Merci d'entrer un e-mail valide.",
                    },
                  })}
                />
              </div>
              <div className="flex justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      type="checkbox"
                      value=""
                      className={`w-4 h-4 border rounded bg-gray-50 focus:ring-3  dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 ${
                        errors.consent
                          ? "border-red-300 focus:ring-red-300"
                          : "border-gray-300 focus:ring-blue-300"
                      }`}
                      // required
                      {...register("consent", { required: true })}
                    />
                  </div>
                  <label
                    htmlFor="remember"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    J'accepte d'étre notifié par email lors de la sortie de
                    Elektreco
                  </label>
                </div>
              </div>
              {dberror ? <p className="text-red-600">Vous étès déjà inscrit</p>: null}
              <button
                type="submit"
                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Notifier moi !
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
